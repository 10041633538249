<template src="./product-detail.html"></template>

<script>
import MainHeader from '../parts/header';
export default {
  name: 'GondolaMiniProductDetail',
  components: {
    MainHeader,
  },
};
</script>
