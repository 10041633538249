<template>
  <div id="main-header" class="section">
    <div class="container">
      <div class="left-side">
        <div class="header_title">
          <h3>Your Beauty Profile</h3>
        </div>
        <div class="beauty_profile_area">
          <ul class="beauty_profile_wrap">
            <li v-for="(st, index) in userSkintypes" :key="index">
              {{ st.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="right-side">
        <div class="avatar_wrap">
          <img v-if="auth.userProfile" class="img-circle" :src="auth.userProfile.image" @error="imageError($event)" />
          <img v-else class="img-circle" src="/static/img/avatar-soco.png" alt="SOCO" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    userSkintypes() {
      return this.$store.state.auth.userSkintypes;
    },
    auth() {
      return this.$store.state.auth;
    },
  },
  created() {
    if (!this.$store.state.QRcode.isLoggedIn) {
      this.$store.dispatch('me').then((response) => {
        if (!response) {
          this.$router.push('/gondola-mini/home');
        }
      });
    }
  },
  methods: {
    imageError(e) {
      e.target.src = '~static/img/avatar-soco.png';
    },
  },
};
</script>
